import * as React from "react";

import Layout from "../../components/layout";
import ExternalLink from "../../components/external-link";
import "./style.css";

const  MiscPage = () => {
  return (
    <Layout title="misc | 🧃" bgColor="#dfe9ed">
      <div id="misc-page">
        <div className="left-60">
          <h1 className="big-text">misc ~</h1>
          <p>
            <ul className="bytes-list">
                <li>
                <strong><ExternalLink
                  url="https://clappy-cheeks.netlify.app/"
                  content="clappy cheeks"
                /></strong>{" "}
                (dec 2021): holiday szn made jollier
                </li>
            </ul>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default MiscPage;
